<template>
  <div>
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-if="currentModalView === 'main'" class="main">
      <h1 class="modal-title">
        Split Document
      </h1>
      <div class="box-container">
        <div class="t-container">
          <div class="document">
            <ThumbnailImage
              class=""
              :index="documentData.id"
              :documentID="documentData.id"
              :pageNumber="1"
              :width="120"
              :height="180"
            />
            <p :title="documentData.displayName">
              {{ displayName }}
            </p>
          </div>
        </div>
      </div>
      <div class="box-container row-container">
        <VasionInput
          id="from-page"
          v-model="fromPage"
          class="input-style"
          inputType="top-white"
          type="number"
          min="0"
          oninput="this.value = Math.abs(this.value)"
          width="100"
          title="From Page:"
          name="from-page"
          :required="true"
        />
        <VasionInput
          id="to-page"
          v-model="toPage"
          class="input-style"
          inputType="top-white"
          type="number"
          min="0"
          :max="documentData.pageCount"
          oninput="this.value = Math.abs(this.value)"
          width="100"
          title="To Page:"
          name="to-page"
          :required="true"
        />
      </div>
      <div class="grid-cntr">
        <VasionInput
          id="file-name-input"
          v-model="fileName"
          name="file-name-input"
          title="FILE NAME"
          placeholder="Enter File Name..."
          inputType="top-white"
          :required="true"
        />
        <div class="file-selector">
          <VasionInput
            id="file-location-input"
            v-model="fileLocation.name"
            name="file-location-input"
            title="FILE LOCATION"
            placeholder="Enter File Location"
            inputType="top-white"
            :readonly="true"
            :required="true"
          />
          <VasionButton
            id="select-file"
            class="button-margin-fix"
            :classProp="'primary'"
            @vasionButtonClicked="currentModalView = 'browse-folders'"
          >
            Select
          </VasionButton>
        </div>
        <VasionDropList
          v-slot="slotItem"
          v-model="splitAsValue"
          title="Save AS"
          :dataArray="splitAs"
          :showSearchField="false"
          type="plain-list"
          displayName="name"
          valueName="value"
          :required="true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div class="checkbox-cntr">
        <VasionCheckbox
          id="delete-original-pages"
          class=""
          name="delete-original-pages"
          :checked="deleteOriginalPages"
          @change="deleteOriginalPages = !deleteOriginalPages"
        >
          Delete Original Pages
        </VasionCheckbox>
        <VasionCheckbox
          id="copy-file-note"
          class=""
          name="copy-file-note"
          :checked="copyFileNote"
          @change="copyFileNote = !copyFileNote"
        >
          Copy File Note
        </VasionCheckbox>
        <VasionCheckbox
          id="copy-index-information"
          class=""
          name="copy-index-information"
          :checked="copyIndexInformation"
          @change="copyIndexInformation = !copyIndexInformation"
        >
          Copy Index Information
        </VasionCheckbox>
      </div>
    </div>
    <footer v-if="currentModalView === 'main'" class="alignRight">
      <VasionButton
        id="btnFolderBrowserCancel"
        classProp="secondary"
        :isRaised="false"
        @vasionButtonClicked="$emit('cancel')"
      >
        Cancel
      </VasionButton>
      <VasionButton
        id="btnFolderBrowserOK"
        classProp="primary"
        :isRaised="false"
        @vasionButtonClicked="splitFiles"
      >
        Split
      </VasionButton>
    </footer>

    <section v-if="currentModalView === 'browse-folders'">
      <div class="folderDialogBrowseDiv">
        <VasionFolders
          @itemSelected="handleDialogSelection"
        />
      </div>
    </section>
    <footer v-if="currentModalView === 'browse-folders'" class="alignRight">
      <VasionButton
        id="btnFolderBrowserCancel"
        classProp="secondary"
        :isRaised="false"
        @vasionButtonClicked="handleBrowseDialogCancel"
      >
        Cancel
      </VasionButton>
      <VasionButton
        id="btnFolderBrowserOK"
        classProp="primary"
        :isRaised="false"
        :isDisabled="!browseDialogSelectionMade"
        @vasionButtonClicked="currentModalView = 'main'"
      >
        OK
      </VasionButton>
    </footer>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import ThumbnailImage from '@/components/document/ThumbnailImage.vue';

export default {
  name: 'SplitDocument',
  components: {
    Loading,
    ThumbnailImage,
  },
  props: {
    documentData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data: function () {
    return {
      browseDialogSelectionMade: false,
      copyFileNote: false,
      copyIndexInformation: false,
      currentModalView: 'main',
      deleteOriginalPages: false,
      fileId: null,
      fileLocation: {name: '', value: ''},
      fileName: '',
      fromPage: 0,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      splitAs: [
        {
          name: 'PDF',
          value: 'pdf',
        },
        {
          name: 'TIFF',
          value: 'tiff',
        },
      ],
      splitAsValue: { },
      toPage: 0,
    }
  },
  computed: {
    displayName() {
      if (!this.documentData.displayName) return ''
      if (this.documentData.displayName.length === this.documentData.displayName.replace(" ", "").length)
        return this.documentData.displayName.substr(0, 16) + "..."
      return this.documentData.displayName.length > 42 ? this.documentData.displayName.substr(0, 39) + "..." : this.documentData.displayName
    },
  },
  methods: {
    handleBrowseDialogCancel() {
      this.browseDialogSelectionMade = false
      this.currentModalView = 'main'
    },
    handleDialogSelection(selectedData) {
      this.fileLocation.name = selectedData.name;
      this.fileLocation.value = selectedData.value;
      this.browseDialogSelectionMade = true
    },
    async splitFiles() {
      if (!this.isFormDataValid())  return

      this.isLoading = true
      const payload = {
        copyFileNote: this.copyFileNote,
        copyIndexInformation: this.copyIndexInformation,
        deleteOriginalPages: this.deleteOriginalPages,
        documentId: this.documentData.id,
        fileName: this.fileName,
        folderId: this.fileLocation.value ? this.fileLocation.value : 0,
        fromPage: this.fromPage,
        saveAs: this.splitAsValue.value,
        toPage: this.toPage,
      }

      try {
        const res = await this.$store.dispatch('document/splitDocument', payload)
        this.isLoading = false
        if (res.Value === '') {
          const messageObject = {
            title: 'The file has been split!',
            subTitle: 'Check your destination folder.',
            modalToClose: 'showSplitModal',
            value: true,
          }
          this.$emit('showSnackbar', messageObject)
          this.$emit('cancel')
        } else {
          const messageObject = {
            title: 'Failed to split file!',
            subTitle: res.Value,
            value: false,
          }
          this.$emit('showSnackbar', messageObject)
        }
      } catch (error) {
        this.displayErrorMessage('The file could not be split.')
      }
    },
    isFormDataValid() {
      let errorMessages = []
      if (this.fromPage <= 0) {
        errorMessages.push('- Please provide a "From Page" bigger than zero.')
      }
      if (this.fromPage > this.documentData?.pageCount) {
        errorMessages.push('- Please provide a "From Page" less than the number of pages.')
      }

      if (this.toPage < this.fromPage) {
        errorMessages.push('- Please provide a "To Page" bigger than "From Page".')
      }
      if (this.toPage > this.documentData?.pageCount) {
        errorMessages.push('- Please provide a "To Page" less than or equal to the number of pages.')
      }
      
      if (!this.fileName) {
        errorMessages.push('- Please provide a File Name.')
      }

      if (!this.fileLocation?.value) {
        errorMessages.push('- Please provide a File Location.')
      }

      if (!this.splitAsValue?.value) {
        errorMessages.push('- Please provide a Save As value.')
      }

      if (errorMessages.length > 0) {
        const message = errorMessages.join('<br />')
        this.displayErrorMessage(message)
        return false
      }
      return true
    },
    displayErrorMessage(message) {
      const messageObject = {
        title: 'Error!',
        subTitle: message,
        value: false,
        doNotRefreshGrid: true,
      }
      this.$emit('showSnackbar', messageObject)
    }
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .alignRight {
    text-align: right;
  }
  .button-margin-fix {
    position: relative;
    top: 17px;
  }
  .folderDialogBrowseDiv {
    width: 400px;
    height: 300px;
    overflow: auto;
  }
  .modal-title {
    @include SubtitleActive;
    font-size: 1.75em;
  }
  .box-container {
    width: 100%;
    padding: 6px;
  }
  .t-container {
    border: solid 1px $grey-100;
    border-radius: 6px;
    box-shadow: 0 .125rem .25rem rgba(33, 37, 41, 0.075)!important;
    padding: 6px;
    align-items: center;
  }
  .document {
    display: flex;
    width: 146px;
    margin: auto;
    background-color: $grey-50;
    border-radius: 2.9px;
    border: solid 1px $grey-100;
    padding: 6px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .document p {
    margin-bottom: unset;
  }
  .row-container {
    text-align: start;
  }
  .row-container div {
    display: inline-block;
    padding-right: 16px;
  }
  div.grid-cntr {
    padding: 6px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 8px;
  }
  .file-selector {
    display: flex;
  }
  .file-selector {
    display: flex;
  }
  ::v-deep .file-selector>div:nth-child(1) {
    flex-grow: 2;
  }
  ::v-deep .file-selector>div:nth-child(2) button {
    margin-right: unset;
  }
  .checkbox-cntr {
    padding: 6px;
  }
  ::v-deep .checkbox-cntr label {
    display: inline-flex;
  }
  @media (min-width: $breakpoint-md) {
    .main {
      min-width: calc(0.88 * $breakpoint-md);
    }
  }
  @media (min-width: $breakpoint-lg) {
    .main {
      min-width: calc(0.88 * $breakpoint-lg);
    }
  }
</style>