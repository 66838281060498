<template>  
  <div id="document-aip" class="vld-parent">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="row">
      <label id="radios-label">ON SUCCESS</label>
      <md-radio id="send-to-folder-radio" v-model="radio" value="send-to-folder">
        Send to Folder
      </md-radio>
      <md-radio id="send-to-workflow-radio" v-model="radio" value="send-to-workflow">
        Send to Workflow
      </md-radio>
    </div>
    <div v-show="radio === 'send-to-folder'" class="row flex-row">
      <div class="half-width">
        <VasionInput
          id="success-folder"
          v-model="successFolderName"
          class="browse-input"
          title="SUCCESS FOLDER"
          inputType="top-white"
          name="success-folder"
          placeholder="Select Folder..."
          :isDisabled="true"
          :isInErrorState="errorSuccessFolder"
        />
        <VasionButton
          id="first-browse-btn"
          class="browse-folders-btn"
          :classProp="'primary'"
          @vasionButtonClicked="toggleBrowseFolderDialog('success')"
        >
          Browse
        </VasionButton>
      </div>
      <div class="half-width">
        <VasionInput
        id="failure-folder"
        v-model="failureFolderName"
        class="browse-input"
        title="FAILURE FOLDER"
        inputType="top-white"
        name="failure-folder"
        placeholder="Select Folder..."
        :isDisabled="true"
        :isInErrorState="errorFailureFolder"
      />
        <VasionButton
          class="browse-folders-btn"
          :classProp="'primary'"
          @vasionButtonClicked="toggleBrowseFolderDialog('failure')"
        >
          Browse
        </VasionButton>
      </div>
    </div>
    <div v-show="radio === 'send-to-workflow'" class="row">
      <VasionDropList
        id="workflow-drop-list"
        v-slot="slotItem"
        v-model="selectedWorkflow"
        :dataArray="workflowList"
        displayName="sName"
        valueName="sName"
        title="Workflow"
        width="100%"
        :isInErrorState="errorWorkflow"
        @input="setSelectedWorkflow($event)"
      >
        {{ slotItem.item.sName }}
      </VasionDropList>
    </div>
    <div class="row" >
      <label id="radios-label">AIP TYPE</label>
      <md-radio id="basic-aip-radio" v-model="radioAip" value="basic-aip" v-on:change="handleAIPTypeChange">
        Vasion AIP
      </md-radio>
      <md-radio id="textract-radio" v-model="radioAip" value="textract" v-on:change="handleAIPTypeChange">
        Amazon Textract
      </md-radio>
    </div>
    <div v-show="radioAip === 'basic-aip'" class="row">
      <div id="add-image-processes-row" class="row">
        <p v-if="aipBasicList && aipBasicList.length === 0" id="no-available-aips" class="vasion-error-text">
          No Available AIPs To Add
        </p>
        <VasionDropList 
          id="aip-drop-list" 
          v-slot="slotItem" 
          v-model="selectedAIPs" 
          :dataArray="aipBasicList"
          displayName="procName" 
          valueName="procName" 
          title="ADD IMAGE PROCESSES" 
          :placeholder="aipDroplistPlaceholder"
          width="100%" 
          :isInErrorState="errorAIPs" 
          type="check-list-search"
        >
          {{ slotItem.item.procName }}
        </VasionDropList>
      </div>
    </div>
    <div v-show="radioAip === 'textract'" class="row">
      <div id="add-image-processes-row" class="row">
        <p v-if="aipTexractList && aipTexractList.length === 0" id="no-available-textract-aips"
          class="vasion-error-text">
          No Available Amazon Textract AIPs To Add
        </p>
        <VasionDropList
          id="aip-textract-drop-list"
          v-slot="slotItem"
          v-model="selectedTextractAIP"
          :dataArray="aipTexractList"
          displayName="procName"
          valueName="procName"
          title="ADD IMAGE PROCESSES"
          :placeholder="aipDroplistPlaceholder"
          width="100%"
          :isInErrorState="errorAIPs"
          type="plain-list"
        >
          {{ slotItem.item.procName }}
        </VasionDropList>
        <p class="warning-message">
          *Only one configuration can be configured with Amazon Textract.
        </p>
      </div>
    </div>
    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'OK'"
      :confirmButtonDisabled="!folderSelected"
      :modalType="'slot'"
      :sync="showBrowseFoldersDialog"
      @confirmButtonClick="folderDialogOK"
      @noButtonClick="handleCancel"
    >
      <div class="folder-dialog-browse">
        <VasionFolders 
          @itemSelected="handleItemSelected" 
        />
      </div>
    </VasionGeneralModal>
    <VasionGeneralModal
      :hideButtons="true"
      buttonGroup="single"
      :modalType="'slot'"
      :sync="showConfirmationDialog"
      @noButtonClick="noConfirmationClick"
    >        
      <VasionConfirmationDialog 
        message="Are you sure you want to change the Configuration Type? This will remove all the added configurations."
        @noButtonClick="noConfirmationClick"
        @yesButtonClick="yesConfirmationClick"
      />
    </VasionGeneralModal>
  </div>       
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'DocumentAIP',
  components: {
    Loading,
  },
  data: function () {
    return {
      aipDroplistPlaceholder: 'Search Image Processes...',
      errorAIPs: false,
      errorFailureFolder: false,
      errorSuccessFolder: false,
      errorWorkflow: false,
      failureFolderID: 0,
      failureFolderName: '',
      folderSelected: false,
      folderSelectionType: '',
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      radio: 'send-to-folder',
      radioAip: 'basic-aip',
      showBrowseFoldersDialog: false,
      selectedAIPs: [],
      selectedTextractAIP: {},
      selectedWorkflow: {},
      successFolderID: 0,
      successFolderName: '',
      tempFolderID: 0,
      tempFolderName: '',
      workflowList: [],
      showConfirmationDialog: false,
      lastSelectedRadioAIP: 'basic-aip',
      aipType: 'Vasion AIP',
      textractPlaceholderText: 'Only one configuration can be configured with Amazon Textract',
    }
  },
  computed: {
    aipBasicList() {
      return this.$store.state.capture.aipData.Values.filter(value => {
        return value.aipType === 'Vasion AIP'
      })
    },
    aipTexractList() {
      return this.$store.state.capture.aipData.Values.filter(value => {
        return value.aipType === 'Amazon Textract'
      })
    },
    selectedAIPIDs() {
      var IDs = []
      if(this.radioAip === 'basic-aip'){
        IDs = this.selectedAIPs.map(item => {          
          return item.aiAIPID
        })
      }
      if(this.radioAip === "textract"){
        IDs.push(this.selectedTextractAIP.aiAIPID)
      }
      return IDs
    },
  },
  async created() {
    this.isLoading = true

    const promises = await Promise.all([
      this.$store.dispatch('workflow/getWorkflowMenuItems'),
      this.$store.dispatch('capture/getAllAipProcs'),
    ])
    // eslint-disable-next-line
    this.workflowList = promises[0]
    this.workflowList.shift()


    this.isLoading = false
  },
  methods: {
    folderDialogOK() {
      if (this.folderSelected) {
        this.showBrowseFoldersDialog = false
        switch (this.folderSelectionType) {
          case 'success':
            this.successFolderID = this.tempFolderID
            this.successFolderName = this.tempFolderName
            break
          case 'failure':
            this.failureFolderID = this.tempFolderID
            this.failureFolderName = this.tempFolderName
            break
          default:
            break
        }
      }
    },
    handleCancel() {
      this.folderSelected = false
      this.showBrowseFoldersDialog = false
    },
    handleItemSelected(item) {
      this.folderSelected = true
      this.tempFolderID = item.value
      this.tempFolderName = item.name
    },
    setSelectedWorkflow(e) { this.selectedWorkflow = e },
    toggleBrowseFolderDialog(selectionType) {
      switch (selectionType) {
        case 'success':
          this.folderSelectionType = 'success'
          break
        case 'failure':
          this.folderSelectionType = 'failure'
          break
        default:
          this.folderSelectionType = ''
          console.warn(`Browsing unknown folder selection.\nReceived: "${selectionType}"`)
          break
      }

      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
    },
    handleAIPTypeChange(e) {     
      if((this.selectedAIPs.length > 0 || this.selectedTextractAIP.aiAIPID > 0) && this.lastSelectedRadioAIP !== e){
        this.showConfirmationDialog = true
      } else {
        this.lastSelectedRadioAIP = e
      }
      
    },
    noConfirmationClick() { 
      this.radioAip = this.lastSelectedRadioAIP
      this.showConfirmationDialog = false 
    },
    yesConfirmationClick() {
      this.selectedAIPs = []
      this.selectedTextractAIP = []
      this.lastSelectedRadioAIP = this.radioAip
      this.showConfirmationDialog = false   
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

#document-aip {
  max-height: 636px;
  max-width: 928px;
  height: 80vh;
  width: 90vw;
  overflow: hidden;
}

.row {
  margin: 0;
}
.flex-row {
  display: flex;
}

#radios-label {
  display: block;
  margin-bottom: -5px;
  margin-top: 25px;
  color: $grey-400;
  font-size: 12px;
  font-weight: 500;
}

.half-width {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.browse-input {
  width: 100%;
}
.browse-folders-btn {
  padding-top: 17px;
}
#first-browse-btn {
  margin-right: 15px;
}

#add-image-processes-row {
  margin-top: 25px;
}

.warning-message {
  margin-top: 8px;
  margin-left: 4px;
  color: #95989a;
}
::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
  color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
  border-color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
  background-color: $secondary !important;
}
</style>
